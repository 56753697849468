import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// CSS
import './index.css';

// MUI
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Main Component
import App from './app';

// Roboto Font
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// import reportWebVitals from './reportWebVitals';

// --------------------------------------------------------------------------------- \\
// ------------------------------ MUI COMPONENTS ----------------------------------- \\
// --------------------------------------------------------------------------------- \\

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        magenta: true;
    }
}
declare module '@mui/material/Fab' {
    interface FabPropsColorOverrides {
        magenta: true;
    }
}

// --------------------------------------------------------------------------------- \\
// -------------------------------- MUI PALETTE ------------------------------------ \\
// --------------------------------------------------------------------------------- \\

declare module '@mui/material/styles' {
    interface Palette {
        magenta: Palette['primary'];
    }
    interface PaletteOptions {
        magenta: PaletteOptions['primary'];
    }
}

// --------------------------------------------------------------------------------- \\
// --------------------------------- MUI THEME ------------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const theme = createTheme({
    palette: {
        magenta: {
            light: '#AF3572',
            main: '#812754',
            dark: '#581A39',
            contrastText: '#fff',
        },
    },
    components: {
        MuiFab: {
            styleOverrides: {
                "root": {
                    "zIndex": "1",
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                "root": {
                    "&.Mui-selected": {
                        "backgroundColor": "rgba(0, 0, 0, 0.1)",
                    },
                    "&.Mui-selected:hover": {
                        "backgroundColor": "rgba(0, 0, 0, 0.1)",
                    },
                }
            },
        },
    },
});

// --------------------------------------------------------------------------------- \\
// ------------------------------------ ROOT --------------------------------------- \\
// --------------------------------------------------------------------------------- \\

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
