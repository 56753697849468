import { Routes, Route, Navigate } from 'react-router-dom';

// Components
import AppBar from 'components/app-bar';
import Footer from 'components/footer';

// Views
import Home from 'views/home';
import Consultations from 'views/consultations';
import Specialities from 'views/specialities';
import Contact from 'views/contact';
import FAQ from 'views/faq';
import LegalNotice from 'views/legal-notice';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------------- APP ------------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function App() {
    return (
        <>
            <AppBar />
            <Routes>
                <Route index path="/" element={<Main />} />
                <Route path="mentions-legales" element={<LegalNotice />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer />
        </>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------------- MAIN ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function Main() {
    return (
        <>
            <Home />
            <Specialities />
            <Consultations />
            <Contact />
            <FAQ />
        </>
    );
}
