
// CSS
import './contact.css';

// MUI
import { Grid, Button } from '@mui/material';
import { Event as EventIcon, Mail as MailIcon } from '@mui/icons-material';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- CONTACT --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Contact() {
    return (
        <section id="Contact" className="Contact">
            <div className="Banner">
                <h1>Contact</h1>
            </div>
            <Grid
                container alignItems="flex-start"
                style={{ padding: '36px 12px' }}
            >
                <Grid
                    item xs={0} xl={1}
                />
                {/* -------------------- HORAIRES -------------------- */}
                <Grid
                    item xs={12} md={6} xl={5}
                    style={{ padding: 12 }}
                >
                    <div className="Contact-container">  
                        <div className="Contact-border">
                            <div className="Contact-card Contact-scheduleCard">
                                <h1>Horaires d'ouverture</h1>
                                <ul>
                                    <li>Lundi : <b>16h-19h</b></li>
                                    <li>Mardi : <b>16h-19h</b></li>
                                    <li>Mercredi : <b>16h-19h</b></li>
                                    <li>Jeudi : <b>16h-19h</b></li>
                                    <li>Vendredi : <b>16h-19h</b></li>
                                    <li>Samedi ½ : <b>9h-13h</b></li>
                                </ul>
                            </div>
                        </div>
                        <Grid
                            container justifyContent="center"
                            style={{ padding: '48px 0px' }}
                        >
                            <Button
                                href="https://diet.alivio.fr/dieteticien/estelle-gaudenzi"
                                rel="external"
                                target="_blank"
                                variant="contained"
                                color="magenta"
                                endIcon={<EventIcon />}
                            >
                                Prendre rendez-vous
                            </Button>
                        </Grid>
                        <p style={{ fontStyle: 'italic', fontWeight: 500, margin: 0, textAlign: 'center' }}>
                            « En cas d'indisponibilité, je vous remercie de bien vouloir annuler votre RDV 48h à l’avance.<br />
                            En effet, ce créneau peut alors être accessible à un patient dans le besoin. »
                        </p>
                    </div>
                </Grid>
                {/* -------------------- COLLABORATION -------------------- */}
                <Grid
                    item xs={12} md={6} xl={5}
                    style={{ padding: 12 }}
                >
                    <div className="Contact-container">
                        <div className="Contact-border">
                            <div className="Contact-card">
                                <h1>Collaboration</h1>
                                <p style={{ textAlign: 'justify' }}>
                                    Vous êtes une association, une entreprise, un professionnel de santé, ... et vous souhaitez travailler sur un projet commun ?
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    Ce serait avec grand plaisir alors n’hésitez pas à me contacter pour pouvoir en discuter. 
                                </p>
                                <ul style={{ fontWeight: 500 }}>
                                    <li>Conférences</li>
                                    <li>Interventions au sein d’une entreprise, association, ...</li>
                                    <li>Ateliers culinaires</li>
                                </ul>
                            </div>
                        </div>
                        <Grid
                            container justifyContent="center"
                            style={{ marginTop: 48 }}
                        >
                            <Button
                                href="mailto:estellegaudenzi.dieteticienne@gmail.com"
                                target="_blank"
                                rel="external"
                                variant="contained"
                                endIcon={<MailIcon />}
                                color="magenta"
                            >
                                Me contacter
                            </Button>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </section>
    );
}
