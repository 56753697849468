import { useEffect, useState } from 'react';
import { Link, useMatch } from 'react-router-dom';

// CSS
import './app-bar.css';

// Lodash
import { debounce } from 'lodash';

// MUI
import { ListItemButton, Drawer, Grid, IconButton, Divider } from '@mui/material';
import { Close as CloseIcon, Apps as MenuIcon } from '@mui/icons-material';

// Services
import { ESection } from 'services/enums';
import { useMediaQueries } from 'services/hooks';

// Assets
import IllustrationImg from 'assets/images/illustration.png';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- APP BAR ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function AppBar() {

    const matchLegalNotice = useMatch('/mentions-legales');
    const { mediaMD, mediaLG } = useMediaQueries();
    const [open, setOpen] = useState(false);
    const [selectedSection, setSelectedSection] = useState<ESection | null>(matchLegalNotice ? null : ESection.HOME);

    useEffect(() => {

        const onScroll = () => {

            let appBar = document.getElementById('AppBar') as HTMLElement;
            let hiddenDiv = document.getElementById('AppBar-hiddenDiv') as HTMLElement;
            let title = document.getElementById('AppBar-backgroundTitle') as HTMLElement;
            let image = document.getElementById('AppBar-image') as HTMLElement;
    
            let appBarMinHeight = getComputedStyle(document.documentElement).getPropertyValue('--app-bar-min-height');
            let appBarMaxHeight = getComputedStyle(document.documentElement).getPropertyValue('--app-bar-max-height');

            if (matchLegalNotice == null && mediaLG && window.scrollY === 0) {
                appBar.style.height = appBarMaxHeight;
                hiddenDiv.style.height = appBarMaxHeight;
                title.style.fontSize = '40px';
                image.style.height = '100px';
                image.style.width = '85px';
                image.style.opacity = '1';
            }
            else if (
                matchLegalNotice
                || (
                    appBar.offsetHeight === 220
                    && (window.scrollY > 0 || !mediaLG)
                )
            ) {
                appBar.style.height = appBarMinHeight;
                hiddenDiv.style.height = appBarMinHeight;
                title.style.fontSize = '20px';
                image.style.height = '50px';
                image.style.width = '42px';
                image.style.opacity = '0';
            }
        };

        const onChangeSection = () => {

            let appBar = document.getElementById('AppBar') as HTMLElement;
            let home = document.getElementById('Home') as HTMLElement;
            let specialities = document.getElementById('Specialities') as HTMLElement;
            let consultations = document.getElementById('Consultations') as HTMLElement;
            let contact = document.getElementById('Contact') as HTMLElement;
            let FAQ = document.getElementById('FAQ') as HTMLElement;

            if (FAQ && (window.scrollY + appBar.offsetHeight) >= FAQ.offsetTop) {
                setSelectedSection(ESection.FAQ);
            }
            else if (contact && (window.scrollY + appBar.offsetHeight) >= contact.offsetTop) {
                setSelectedSection(ESection.CONTACT);
            }
            else if (consultations && (window.scrollY + appBar.offsetHeight) >= consultations.offsetTop) {
                setSelectedSection(ESection.CONSULTATIONS);
            }
            else if (specialities && (window.scrollY + appBar.offsetHeight) >= specialities.offsetTop) {
                setSelectedSection(ESection.SPECIALITIES);
            }
            else if (home && (window.scrollY + appBar.offsetHeight) >= home.offsetTop) {
                setSelectedSection(ESection.HOME);
            }
            else {
                setSelectedSection(null);
            }
        };

        onScroll();
        onChangeSection();

        document.addEventListener('scroll', onScroll);
        document.addEventListener('touchmove', onScroll);
        document.addEventListener('scroll', debounce(onChangeSection, 200, { leading: true }));
        document.addEventListener('touchmove', debounce(onChangeSection, 200, { leading: true }));

        return () => {
            document.removeEventListener('scroll', onScroll);
            document.removeEventListener('touchmove', onScroll);
            document.removeEventListener('scroll', onChangeSection);
            document.removeEventListener('touchmove', onChangeSection);
        };

        // eslint-disable-next-line
    }, [mediaLG, matchLegalNotice]);

    return (
        <>
            <header id="AppBar" className="AppBar">
                {/* -------------------- BACKGROUND -------------------- */}
                <div className="AppBar-background">
                    <div
                        id="AppBar-backgroundTitle"
                        className="AppBar-backgroundTitle"
                    >
                        <span>
                            Diététicienne - Nutritionniste
                        </span>
                        <div className="AppBar-image">
                            <div>
                                <img
                                    id="AppBar-image"
                                    alt="Illustration"
                                    src={IllustrationImg}
                                    width={85}
                                    height={100}
                                    loading="eager"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* -------------------- MENU -------------------- */}
                <div className="AppBar-menu">
                    <div className="AppBar-menuList">
                        {mediaMD ? (
                            <MenuList
                                selectedSection={selectedSection}
                            />
                        ) : (
                            <>
                                <IconButton
                                    onClick={() => setOpen(true)}
                                    color="inherit"
                                >
                                    <MenuIcon fontSize="large" />
                                </IconButton>
                                <Drawer
                                    open={open}
                                    onClose={() => setOpen(false)}
                                    anchor="top"
                                    ModalProps={{ style: { zIndex: 1400 } }}
                                >
                                    <Grid
                                        container justifyContent="end"
                                        style={{ padding: '12px 8px' }}
                                    >
                                        <IconButton
                                            onClick={() => setOpen(false)}
                                            color="inherit"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                    <Divider />
                                    <MenuList
                                        selectedSection={selectedSection}
                                        onClose={() => setOpen(false)}
                                    />
                                </Drawer>
                            </>
                        )}
                    </div>
                </div>
            </header>
            <div
                id="AppBar-hiddenDiv"
                className="AppBar-hiddenDiv"
            />
        </>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- MENU LIST --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function MenuList({ selectedSection, onClose } : { selectedSection: ESection | null, onClose?: VoidFunction }) {

    const matchLegalNotice = useMatch('/mentions-legales');

    const onScrollTo = (sectionName: ESection) => {

        let section : HTMLElement | null = null;

        switch (sectionName) {

            case ESection.HOME:
                section = document.getElementById('Home');
                break;

            case ESection.SPECIALITIES:
                section = document.getElementById('Specialities');
                break;

            case ESection.CONSULTATIONS:
                section = document.getElementById('Consultations');
                break;

            case ESection.CONTACT:
                section = document.getElementById('Contact');
                break;

            case ESection.FAQ:
                section = document.getElementById('FAQ');
                break;

            default:
                break;
        }

        if (onClose) {
            onClose();
        }

        if (section) {
            let appBar = document.getElementById('AppBar') as HTMLElement;
            window.scroll({ left: 0, top: section.offsetTop - appBar.offsetHeight });
        }
    };

    return (
        <>
            {matchLegalNotice ? (
                <ListItemButton
                    component={Link}
                    to="/"
                    onClick={onClose}
                    className="AppBar-listItemButton"
                >
                    Retour à l'accueil
                </ListItemButton>
            ) : (
                <>
                    <ListItemButton
                        onClick={() => onScrollTo(ESection.HOME)}
                        selected={selectedSection === ESection.HOME}
                        className="AppBar-listItemButton"
                    >
                        Accueil
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => onScrollTo(ESection.SPECIALITIES)}
                        selected={selectedSection === ESection.SPECIALITIES}
                        className="AppBar-listItemButton"
                    >
                        Spécialités
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => onScrollTo(ESection.CONSULTATIONS)}
                        selected={selectedSection === ESection.CONSULTATIONS}
                        className="AppBar-listItemButton"
                    >
                        Consultations
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => onScrollTo(ESection.CONTACT)}
                        selected={selectedSection === ESection.CONTACT}
                        className="AppBar-listItemButton"
                    >
                        Contact
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => onScrollTo(ESection.FAQ)}
                        selected={selectedSection === ESection.FAQ}
                        className="AppBar-listItemButton"
                    >
                        F.A.Q
                    </ListItemButton>
                </>
            )}
        </>
    );
}
