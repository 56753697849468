
// CSS
import './consultations.css';

// MUI
import { Fab, Button, Grid, Divider } from '@mui/material';
import { Instagram as InstagramIcon, Event as EventIcon } from '@mui/icons-material';

// Services
import { useMediaQueries } from 'services/hooks';

// Components
import Layout from 'components/layout';

// Assets
import Logo from 'assets/images/logo.png';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- CONSULTATIONS ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Consultations() {

    const { mediaSM } = useMediaQueries();

    return (
        <section
            id="Consultations"
            className="Consultations"
        >
            <div className="Banner">
                <h1>Consultations</h1>
            </div>
            {!mediaSM && (
                <Divider />
            )}
            <Layout containerProps={{ padding: mediaSM ? '48px 24px' : undefined }}>
                <GridTemplate />
            </Layout>
        </section>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- GRID TEMPLATE ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

function GridTemplate() {
    return (
        <>
            <div className="Consultations-gridTemplate">
                {/* -------------------- VISIO -------------------- */}
                <div
                    id="Consultations-gridTemplate-item1"
                    className="Consultations-gridTemplate-item"
                >
                    <h1 style={{ marginTop: 0, textAlign: 'center' }}>
                        À distance (visio)
                    </h1>
                    <p
                        className="Consultations-magentaColor"
                        style={{ textAlign: 'center', fontSize: 18, fontStyle: 'italic' }}
                    >
                        Pourquoi effectuer des consultations à distance ?
                    </p>
                    <ul style={{ textAlign: 'justify' }}>
                        <li>Vous pouvez facilement me consulter même si vous n'êtes pas dans la même région</li>
                        <li>Vous ne perdez pas de temps dans les transports et cela vous permet de faciliter votre organisation</li>
                        <li>Vous pouvez être plus à l'aise pour échanger avec moi lorsque vous restez dans un environnement rassurant et que vous connaissez</li>
                    </ul>
                    <p style={{ textAlign: 'center', fontStyle: 'italic' }}>
                        Vous recevrez un lien de connexion par mail juste avant la consultation.
                    </p>
                    <h3 className="Consultations-magentaColor">
                        Tarifs
                    </h3>
                    <p>
                        1ère consultation (1h-1h15) : <b>55 euros</b>
                        <br />
                        Consultation de suivi (30-40min) : <b>40 euros</b>
                    </p>
                </div>
                {/* -------------------- RECETTE 1 -------------------- */}
                <div
                    id="Consultations-gridTemplate-item2"
                    className="Consultations-gridTemplate-image Consultations-recipe1"
                >
                    <div className="Consultations-gridTemplate-logo">
                        <img
                            alt="Estelle Gaudenzi | Diététicienne - Nutritionniste"
                            src={Logo}
                            width={150}
                            height={50}
                            loading="lazy"
                        />
                    </div>
                    <div className="Consultations-gridTemplate-fab">
                        <Fab
                            href="https://www.instagram.com/p/CWF6IqFo9FD/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
                            target="_blank"
                            rel="external"
                            size="small" color="magenta"
                        >
                            <InstagramIcon />
                        </Fab>
                    </div>
                </div>
                {/* -------------------- DOMICILE -------------------- */}
                <div
                    id="Consultations-gridTemplate-item3"
                    className="Consultations-gridTemplate-item"
                >
                    <h1 style={{ marginTop: 0, textAlign: 'center' }}>
                        À domicile
                    </h1>
                    <p
                        className="Consultations-magentaColor"
                        style={{ textAlign: 'center', fontSize: 18, fontStyle: 'italic' }}
                    >
                        Pourquoi effectuer des consultations à domicile ?
                    </p>
                    <ul style={{ textAlign: 'justify' }}>
                        <li>Si vous avez l'impossibilité de vous déplacer, je peux venir à votre domicile pour réaliser les consultations</li>
                        <li>Vous pouvez être plus à l'aise en restant dans votre cocon</li>
                        <li>Divers ateliers peuvent être mis en place : préparations de liste de courses, réalisations de menus, analyser ensemble les produits que vous avez, proposer des alternatives que vous pouvez facilement mettre en place dans votre quotidien...</li>
                    </ul>
                    <h3 className="Consultations-magentaColor">
                        Tarifs
                    </h3>
                    <p>
                        1ère consultation (1h-1h15) : <b>55 euros</b>
                        <br />
                        Consultation de suivi (30-40min) : <b>40 euros</b>
                        <br />
                        <b>+ frais de déplacement</b>
                        <br />
                        <br />
                        <i>Ateliers : me contacter pour tout renseignement</i>
                    </p>
                </div>
                {/* -------------------- RECETTE 2 -------------------- */}
                <div
                    id="Consultations-gridTemplate-item4"
                    className="Consultations-gridTemplate-image Consultations-recipe2"
                >
                    <div className="Consultations-gridTemplate-logo">
                        <img
                            alt="Estelle Gaudenzi | Diététicienne - Nutritionniste"
                            src={Logo}
                            width={150}
                            height={50}
                            loading="lazy"
                        />
                    </div>
                    <div className="Consultations-gridTemplate-fab">
                        <Fab
                            href="https://www.instagram.com/p/CwJ9x3eInEU/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
                            target="_blank"
                            rel="external"
                            size="small" color="magenta"
                        >
                            <InstagramIcon />
                        </Fab>
                    </div>
                </div>
                {/* -------------------- DEROULEMENT -------------------- */}
                <div
                    id="Consultations-gridTemplate-item5"
                    className="Consultations-gridTemplate-item"
                >
                    <h1 style={{ marginTop: 0, textAlign: 'center' }}>
                        Déroulement des consultations
                    </h1>
                    <h3 className="Consultations-magentaColor">
                        Première consultation « Bilan »
                    </h3>
                    <p style={{ textAlign: 'justify' }}>
                        Lors de ce premier rendez-vous, nous allons échanger afin que nous puissions faire plus ample connaissance.
                        Je vais vous écouter et vous questionner sur vos habitudes et nous allons revenir sur certaines bases nutritionnelles.
                        <br />
                        <br />
                        Enfin, nous allons poser les premiers objectifs et je vous partagerai des conseils personnalisés et réalisables, qui resteront
                        accessible sur votre dossier.
                    </p>
                    <h3 className="Consultations-magentaColor">
                        Suivi
                    </h3>
                    <p style={{ textAlign: 'justify' }}>
                        Nous échangerons sur votre progression, les éventuelles difficultés rencontrées mais aussi les réussites.
                        Puis, nous aborderons petit à petit de nouvelles thématiques qui seront importantes tout au long de votre parcours.
                    </p>
                    <Grid
                        container justifyContent="center"
                        style={{ marginTop: 24 }}
                    >
                        <Button
                            href="https://diet.alivio.fr/dieteticien/estelle-gaudenzi"
                            rel="external"
                            target="_blank"
                            variant="contained"
                            color="magenta"
                            endIcon={<EventIcon />}
                        >
                            Prendre rendez-vous
                        </Button>
                    </Grid>
                </div>
                {/* -------------------- RECETTE 3 -------------------- */}
                <div
                    id="Consultations-gridTemplate-item6"
                    className="Consultations-gridTemplate-image Consultations-recipe3"
                >
                    <div className="Consultations-gridTemplate-logo">
                        <img
                            alt="Estelle Gaudenzi | Diététicienne - Nutritionniste"
                            src={Logo}
                            width={150}
                            height={50}
                            loading="lazy"
                        />
                    </div>
                    <div className="Consultations-gridTemplate-fab">
                        <Fab
                            href="https://www.instagram.com/p/CITbrC0h0j5/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
                            target="_blank"
                            rel="external"
                            size="small" color="magenta"
                        >
                            <InstagramIcon />
                        </Fab>
                    </div>
                </div>
            </div>
        </>
    )
}