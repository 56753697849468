
// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- SECTION ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export enum ESection {
    HOME = 'HOME',
    SPECIALITIES = 'SPECIALITIES',
    CONSULTATIONS = 'CONSULTATIONS',
    CONTACT = 'CONTACT',
    FAQ = 'FAQ',
}
