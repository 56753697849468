
// CSS
import './specialities.css';

// MUI
import { Grid } from '@mui/material';

// Services
import { useMediaQueries } from 'services/hooks';

// Assets
import FemmeEnceinteImg from 'assets/images/femme-enceinte.jpg'
import ReequilibrageAlimentaireImg from 'assets/images/reequilibrage-alimentaire.jpg'
import AlimentationTherapeutiqueImg from 'assets/images/alimentation-therapeutique.jpg'
import IntolerancesImg from 'assets/images/intolerances.jpg'

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- SPECIALITIES ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Specialities() {

    const { mediaXL } = useMediaQueries();

    return (
        <section
            id="Specialities"
            className="Specialities"
        >
            <div className="Banner">
                <h1>Spécialités</h1>
            </div>
            <div className="Specialities-container">
                <Grid
                    container
                    alignItems="center"
                    paddingY={3}
                >
                    {/* -------------------- REEQUILIBRAGE ALIMENTAIRE -------------------- */}
                    <Grid
                        item xs={12} md={6} lg={3}
                        padding={3}
                    >
                        <div className="Specialities-gridItem">
                            <img
                                alt="Rééquilibrage alimentaire, conseils en nutrition"
                                src={ReequilibrageAlimentaireImg}
                                width={mediaXL ? 300 : 260}
                                height={mediaXL ? 300 : 260}
                                loading="lazy"
                            />
                            <div>
                                Rééquilibrage alimentaire,<br />
                                conseils en nutrition
                            </div>
                        </div>
                    </Grid>
                    {/* -------------------- ALIMENTATION THERAPEUTIQUE -------------------- */}
                    <Grid
                        item xs={12} md={6} lg={3}
                        padding={3}
                    >
                        <div className="Specialities-gridItem">
                            <img
                                alt="Alimentation thérapeutique"
                                src={AlimentationTherapeutiqueImg}
                                width={mediaXL ? 300 : 260}
                                height={mediaXL ? 300 : 260}
                                loading="lazy"
                            />
                            <div>
                                Alimentation thérapeutique (HTA, cholestérol, ...)<br />
                                Perte de poids, prise de poids
                            </div>
                        </div>
                    </Grid>
                    {/* -------------------- INTOLERANCES -------------------- */}
                    <Grid
                        item xs={12} md={6} lg={3}
                        padding={3}
                    >
                        <div className="Specialities-gridItem">
                            <img
                                alt="Intolérances et allergies alimentaires"
                                src={IntolerancesImg}
                                width={mediaXL ? 300 : 260}
                                height={mediaXL ? 300 : 260}
                                loading="lazy"
                            />
                            <div>
                                Intolérances et<br />
                                allergies alimentaires
                            </div>
                        </div>
                    </Grid>
                    {/* -------------------- SUIVI DE LA FEMME -------------------- */}
                    <Grid
                        item xs={12} md={6} lg={3}
                        padding={3}
                    >
                        <div className="Specialities-gridItem">
                            <img
                                alt="Suivi de la femme enceinte, diabète gestationnel"
                                src={FemmeEnceinteImg}
                                width={mediaXL ? 300 : 260}
                                height={mediaXL ? 300 : 260}
                                loading="lazy"
                            />
                            <div>
                                Suivi de la femme enceinte,<br />
                                diabète gestationnel
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
}
