
// CSS
import './home.css';

// MUI
import { Button } from '@mui/material';
import { Event as ArrowIcon } from '@mui/icons-material';

// Assets
import IllustrationCouverts from 'assets/images/couverts.png';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------------- HOME ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Home() {
    return (
        <section
            id="Home"
            className="Home"
        >
            <div className="Home-container1">
                <div className="Home-border">
                    <div style={{ padding: 6 }}>
                        <div
                            className="Home-picture"
                            style={{ height: 280 }}
                        />
                    </div>
                </div>
                <div style={{ marginTop: 48 }}>
                    <Button
                        href="https://diet.alivio.fr/dieteticien/estelle-gaudenzi"
                        rel="external"
                        target="_blank"
                        variant="contained"
                        color="magenta"
                        endIcon={<ArrowIcon />}
                        style={{ fontSize: 16 }}
                    >
                        Prendre rendez-vous
                    </Button>
                </div>
            </div>
            <div className="Home-container2">
                <div className="Home-welcome">
                    <div>
                        <h1>
                            Bienvenue
                        </h1>
                        <div className="Home-welcome-dot Home-dot1" />
                        <div className="Home-welcome-dot Home-dot2" />
                        <div className="Home-welcome-dot Home-dot3" />
                    </div>
                </div>
                <div className="Home-border Home-largeBorder">
                    <div className="Home-text">
                        <div>
                            <p>
                                Je me présente, je m’appelle Estelle Gaudenzi et je suis diététicienne-nutritionniste.
                            </p>
                            <p>
                                Grande fan de cuisine depuis de nombreuses années, je me suis intéressée de plus près au domaine de la nutrition
                                et j'y ai découvert une véritable passion.<br />
                                <br />
                                J'ai alors décidé d'exercer comme diététicienne en parallèle de mon premier métier en tant qu'infirmière.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Home-container3">
                <div
                    className="Home-border"
                    style={{ alignSelf: 'flex-start' }}
                >
                    <div className="Home-text">
                        <div>
                            Ici la bienveillance est le maître mot. Je suis là pour vous écouter, vous aider et vous accompagner tout au long de votre parcours.
                            Ceci dans le but de vous réconcilier avec votre alimentation et trouver un équilibre dans votre quotidien.
                        </div>
                    </div>
                </div>
                <div className="Home-illustration">
                    <img
                        alt="Illustration couverts"
                        src={IllustrationCouverts}
                        height={200}
                        width={380}
                        loading="eager"
                    />
                </div>
            </div>
        </section>
    );
}
