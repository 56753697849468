import { useState, useEffect } from 'react';
import { useMatch, Link } from 'react-router-dom';

// CSS
import './footer.css';

// Lodash
import debounce from 'lodash.debounce';

// MUI
import { Grid, ListItemButton, IconButton, Snackbar } from '@mui/material';
import {
    Phone as PhoneIcon, Mail as MailIcon, LinkedIn as LinkedInIcon, ArrowUpward as ArrowIcon,
    Instagram as InstagramIcon, Facebook as FacebookIcon, Close as CloseIcon
} from '@mui/icons-material';

// Services
import { useMediaQueries } from 'services/hooks';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- FOOTER ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Footer() {

    const matchLegalNotice = useMatch('/mentions-legales');
    const { mediaMD, mediaDesktop } = useMediaQueries();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [scrollTopEnabled, setScrollTopEnabled] = useState(false);

    const writePhoneNumberInClipboard = () => {
        navigator.clipboard.writeText('+33744780108').then(
            () => {
                setOpenSnackbar(true);
            },
            () => {
                console.log("Le numéro de téléphone n'a pas pu être copié.");
            },
        );
    };

    useEffect(() => {

        const handleScrollTopDisplay = () => {
            if (window.scrollY === 0) {
                setScrollTopEnabled(false);
            }
            if (window.scrollY > 0) {
                setScrollTopEnabled(true);
            }
        };
        window.addEventListener('scroll', debounce(handleScrollTopDisplay, 300));

        return () => {
            window.removeEventListener('scroll', handleScrollTopDisplay);
        };

        // eslint-disable-next-line
    }, []);

    return (
        <>
            {matchLegalNotice == null && (
                <div className="Footer-legalNotice">
                    <span>Gaudenzi Estelle © {new Date().getFullYear()}. Tous droits réservés.</span>
                    &nbsp;
                    <Link to="/mentions-legales">
                        Mentions légales.
                    </Link>
                </div>
            )}
            <footer className="Footer">
                <Grid container alignItems="center">
                    <Grid item xs={12} md="auto">
                        <ListItemButton
                            onClick={writePhoneNumberInClipboard}
                            style={{ padding: 12, justifyContent: mediaMD ? undefined : 'center' }}
                        >
                            <PhoneIcon />
                            <span className="Footer-listItemButton-span">
                                07.44.78.01.08
                            </span>
                        </ListItemButton>
                        <Snackbar
                            open={openSnackbar}
                            onClose={() => setOpenSnackbar(false)}
                            autoHideDuration={3000}
                            message="Numéro de téléphone copié !"
                            action={
                                <IconButton
                                    onClick={() => setOpenSnackbar(false)}
                                    size="small" color="inherit"
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md="auto">
                        <ListItemButton
                            href="mailto:estellegaudenzi.dieteticienne@gmail.com"
                            rel="external"
                            target="_blank"
                            style={{ padding: 12, justifyContent: mediaMD ? undefined : 'center' }}
                        >
                            <MailIcon />
                            <span className="Footer-listItemButton-span">
                                estellegaudenzi.dieteticienne@gmail.com
                            </span>
                        </ListItemButton>
                    </Grid>
                    <Grid
                        item xs={false} sm
                    />
                    <Grid
                        item xs={12} md="auto"
                        style={{ textAlign: mediaMD ? undefined : 'center' }}
                    >
                        <IconButton
                            href="https://www.instagram.com/cookingwhappiness/"
                            target="_blank"
                            rel="external"
                            size="large"
                            style={{ color: 'white' }}
                        >
                            <InstagramIcon />
                        </IconButton>
                        <IconButton
                            href="https://www.facebook.com/profile.php?id=61552296312196&locale=fr_FR"
                            target="_blank"
                            rel="external"
                            size="large"
                            style={{ color: 'white' }}
                        >
                            <FacebookIcon />
                        </IconButton>
                        <IconButton
                            href="https://www.linkedin.com/in/estelle-gaudenzi-b91171254/"
                            target="_blank"
                            rel="external"
                            size="large"
                            style={{ color: 'white' }}
                        >
                            <LinkedInIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {/* -------------------- SCROLL TO TOP BUTTON -------------------- */}
                {(mediaDesktop && scrollTopEnabled) && (
                    <div
                        onClick={() => window.scroll({ left: 0, top: 0 })}
                        className="Footer-scrollTop"
                    >
                        <ArrowIcon />
                    </div>
                )}
            </footer>
            <div
                className="Footer-hiddenDiv"
            />
        </>
    );
}
