import { useState } from 'react';

// CSS
import './faq.css';

// MUI
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

// Components
import Layout from 'components/layout';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------------ FAQ ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function FAQ() {

    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <section id="FAQ" className="FAQ">
            <div className="Banner">
                <h1>Foire Aux Questions</h1>
            </div>
            <Layout containerProps={{ padding: '48px 24px' }}>
                {/* -------------------- ACCORDION 1 -------------------- */}
                <Accordion
                    expanded={expanded === 'Accordion1'}
                    onChange={handleChange('Accordion1')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: '#812754' }} />}
                        className="FAQ-AccordionSummary"
                    >
                        Où prendre rendez-vous ?
                    </AccordionSummary>
                    <AccordionDetails>
                        Vous avez la possibilité de prendre rdv directement sur le site internet (via la plateforme Alivio), par mail ou bien par téléphone.<br /> 
                        Retrouvez toutes les informations dans la section Contact.
                    </AccordionDetails>
                </Accordion>
                {/* -------------------- ACCORDION 2 -------------------- */}
                <Accordion
                    expanded={expanded === 'Accordion2'}
                    onChange={handleChange('Accordion2')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: '#812754' }} />}
                        className="FAQ-AccordionSummary"
                    >
                        Est-ce que je dois préparer des documents spécifiques pour les consultations ?
                    </AccordionSummary>
                    <AccordionDetails>
                        Oui si vous avez des résultats médicaux en votre possession, je peux être amenée à vous les demander afin de compléter au mieux votre dossier (notamment les bilans sanguins). 
                    </AccordionDetails>
                </Accordion>
                {/* -------------------- ACCORDION 3 -------------------- */}
                <Accordion
                    expanded={expanded === 'Accordion3'}
                    onChange={handleChange('Accordion3')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: '#812754' }} />}
                        className="FAQ-AccordionSummary"
                    >
                        Quelle est la durée d’un accompagnement diététique ?
                    </AccordionSummary>
                    <AccordionDetails>
                        La durée de l’accompagnement va dépendre de votre motif de consultation, de vos besoins, de votre évolution...<br />
                        Cela peut  donc varier de quelques mois à environ 1 an. 
                    </AccordionDetails>
                </Accordion>
                {/* -------------------- ACCORDION 4 -------------------- */}
                <Accordion
                    expanded={expanded === 'Accordion4'}
                    onChange={handleChange('Accordion4')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: '#812754' }} />}
                        className="FAQ-AccordionSummary"
                    >
                        Est-ce que les consultations diététiques sont remboursées par l’Assurance Maladie ?
                    </AccordionSummary>
                    <AccordionDetails>
                        Non, pour le moment la sécurité sociale ne les prend pas en charge mais certaines <b>mutuelles</b> prennent en charge un forfait ou un certain nombre de séances de suivi diététique sur une année.<br />
                        N’hésitez pas à vous renseigner auprès de votre complémentaire.
                    </AccordionDetails>
                </Accordion>
                {/* -------------------- ACCORDION 5 -------------------- */}
                <Accordion
                    expanded={expanded === 'Accordion5'}
                    onChange={handleChange('Accordion5')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: '#812754' }} />}
                        className="FAQ-AccordionSummary"
                    >
                        Est-ce qu’un accompagnement peut se faire uniquement par visioconférence ?
                    </AccordionSummary>
                    <AccordionDetails>
                        Oui, les consultations à distance se déroulent de la même manière que si elles avaient été réalisées en présentiel.<br />
                        Vous retrouvez les avantages de la réalisation des consultations en visio dans la section Consultations.
                    </AccordionDetails>
                </Accordion>
            </Layout>
        </section>
    );
}
