import { useEffect } from 'react';

// CSS
import './legal-notice.css';

// MUI
import { Grid } from '@mui/material';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ LEGAL NOTICE -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function LegalNotice() {

    useEffect(() => {
        if (window.scrollY > 0) {
            window.scroll({ left: 0, top: 0 });
        }
    }, []);

    return (
        <section className="LegalNotice">
            <div className="Banner">
                <h1>Mentions légales</h1>
            </div>
            <Grid container justifyContent="center">
                <div style={{ padding: 24 }}>
                    <h3>ÉDITION DU SITE</h3>
                    <p>
                        Site : https://estellegaudenzi-dieteticienne.fr/<br />
                        Société : GAUDENZI Estelle<br />
                        Numéro SIRET : 98089015600014<br />
                        Représentant : Estelle GAUDENZI
                    </p>
                    <h3>HÉBERGEMENT</h3>
                    <p>
                        Le site est hébergé par la société OVH SAS, situé 2 rue Kellermann - 59100 Roubaix - France.
                    </p>
                    <h3>DIRECTEUR DE LA PUBLICATION</h3>
                    <p>
                        La directrice de la publication du site est Estelle GAUDENZI.
                    </p>
                    <h3>ME CONTACTER</h3>
                    <p>
                        Par téléphone : +33 (0)7 44 78 01 08<br />
                        Par email : estellegaudenzi.dieteticienne@gmail.com
                    </p>
                </div>
            </Grid>
        </section>
    );
}
